import * as React from "react"
import { useState, useLayoutEffect, useRef } from "react"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import "../components/css/BlogDetail.scss"
import { Link } from "gatsby"
import { AiFillLinkedin } from "react-icons/ai"
import { FaTwitter } from "react-icons/fa"
import { FaYoutube } from "react-icons/fa"
import { FiArrowLeft } from "react-icons/fi"

import { BlogData } from "../components/blogDetails"
import blogPerformancsustain from "../images/blog/Performancsustain_img.jpg"

const Performanceandsustainability = () => {
  // Copy blog data to a state variable
  const [blogList, setBlogList] = React.useState([])

  React.useLayoutEffect(() => {
    setBlogList([...BlogData])
  }, [])

  const ref = useRef(null)

  console.log(ref?.current?.innerText, "ref")

  // Specify the id you want to find
  const targetId = 3

  // Use the find method to get the data for the item with the matching id
  const targetItem = BlogData.find(item => item.id === targetId)

  const wordsPerMinute = 120

  // if (targetItem) {
  //   return <p>item</p>
  // } else {
    return (
      <Layout>
        <Seo title="EdgeQ | Performance and Sustainability:Not Mutually Exclusive" />

        <section className="News-detail blog-detail" ref={ref}>
          <Container>
            <Row>
              <div className="detail-head">
                <Link to="/blog" className="back-btn">
                  <FiArrowLeft /> Blog Page
                </Link>

                {/* <h2>{targetItem.blogTitle}</h2> */}
                <h2>Performance and Sustainability:Not Mutually Exclusive</h2>
              </div>

              <div className="detail-para">
                <p className="text-left">
                  <i>
                    Redesigning the next generation of chips to do more with less
                  </i>
                </p>

                <p className="text-left ">

                  by <a href="https://www.linkedin.com/in/neilwinrow/?originalSubdomain=uk" target="_blank">Neil Winrow</a> | VP of Product Management

                </p>

                <div className="date_time">
                  {/* <h5>{targetItem.blogDate}</h5> */}
                  <h5>06 Oct, 2023</h5>

                  <h6>
                    <svg
                      width="19"
                      height="18"
                      viewBox="0 0 19 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M9.5 0C11.8869 0 14.1761 0.948211 15.864 2.63604C17.5518 4.32387 18.5 6.61305 18.5 9C18.5 11.3869 17.5518 13.6761 15.864 15.364C14.1761 17.0518 11.8869 18 9.5 18C7.11305 18 4.82387 17.0518 3.13604 15.364C1.44821 13.6761 0.5 11.3869 0.5 9C0.5 6.61305 1.44821 4.32387 3.13604 2.63604C4.82387 0.948211 7.11305 0 9.5 0ZM9.5 0.947368C7.36431 0.947368 5.31609 1.79577 3.80593 3.30593C2.29577 4.81609 1.44737 6.86431 1.44737 9C1.44737 11.1357 2.29577 13.1839 3.80593 14.6941C5.31609 16.2042 7.36431 17.0526 9.5 17.0526C10.5575 17.0526 11.6046 16.8443 12.5816 16.4397C13.5586 16.035 14.4463 15.4418 15.1941 14.6941C15.9418 13.9463 16.535 13.0586 16.9397 12.0816C17.3443 11.1046 17.5526 10.0575 17.5526 9C17.5526 6.86431 16.7042 4.81609 15.1941 3.30593C13.6839 1.79577 11.6357 0.947368 9.5 0.947368ZM9.02632 3.78947H9.97368V8.92421L14.4263 11.4916L13.9526 12.3158L9.02632 9.47368V3.78947Z"
                        fill="#6A6A6A"
                      />
                    </svg>
                    {Math.ceil(
                      ref?.current?.innerText?.split(" ").length /
                      wordsPerMinute
                    )}{" "}
                    min
                    {Math.ceil(
                      ref?.current?.innerText?.split(" ").length /
                      wordsPerMinute
                    ) !== 1
                      ? "s"
                      : ""}
                  </h6>
                </div>

                <div className="ajit-social">
                  <ul className="social-icons">
                    <li>
                      <a
                        aria-label="Save"
                        href="https://www.linkedin.com"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <AiFillLinkedin />
                      </a>
                    </li>
                    <li>
                      <a
                        aria-label="Save"
                        href="https://twitter.com/edgeq_inc?lang=en"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaTwitter />
                      </a>
                    </li>
                    <li>
                      <a
                        aria-label="Save"
                        href="https://www.youtube.com/@EdgeQInc/featured"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaYoutube />
                      </a>
                    </li>
                  </ul>
                </div>

                <div className="blog_content_padd">
                  <div className="blog_img_one">
                    <img src={blogPerformancsustain} alt="blogPerformancsustain" />
                  </div>


                  {/* <p className="text-left">
                    {targetItem.blogDetails}
                  </p> */}

                  <p className="text-left">
                    Spider-Man’s Uncle Ben once uttered the words: “With great power comes great responsibility.” His wisdom applies universally, including the promise of 5G and its highly anticipated transformative properties. As the world transitions into harnessing all the new 5G capabilities and performance, the semiconductor industry must first address a perennial engineering dilemma: How do we take a complex technology, like the modem chip, that has proven to be a challenge for companies with the largest R&D budgets and make it affordable, faster, and better, all while delivering a responsible design that treads lightly on the earth?
                  </p>

                  <h3>Climate change must be considered across industries</h3>

                  <p className="text-left">
                    The semiconductor industry isn’t only impacted by climate change, as evident in the Taiwan droughts, but also contributes to it via the ever-increasing carbon emissions from the chip manufacturing process. <a className="text-underline" target="_blank" href="https://www2.deloitte.com/us/en/pages/technology-media-and-telecommunications/articles/semiconductor-industry-outlook.html">Deloitte’s 2023 Semiconductor Industry Outlook Report</a> predicts the information and communications technology industry to account for20% of global electricity demand by 2030.
                  </p>

                  <p className="text-left">
                  It’s not just the fabs—everyone plays a role. Leading operators and equipment vendors have started to prioritize sustainability as shown in the <a className="text-underline" target="_blank" href="https://www.gsma.com/betterfuture/wp-content/uploads/2022/05/Moble-Net-Zero-State-of-the-Industry-on-Climate-Action-2022.pdf">Mobile Net Zero report</a>. As chip designers, we must also be committed to building products that deliver on the promise of advanced connectivity, but do so in a sustainable manner. The EdgeQ team tackled that by prioritizing low-power consumption and hardware longevity.
                  </p>

                  <h3>Just how costly is quality 5G?</h3>

                  <p className="text-left">
                  The rollout and adoption of 5G have been hindered by numerous factors, but an undeniable one is cost. Traditionally, the telecom industry needed to resort to rip and replace to transition to new equipment—a cumbersome transition process that requires physically replacing the equipment. <a className="text-underline" target="_blank" href="https://www.mckinsey.com/~/media/mckinsey/industries/technology%20media%20and%20telecommunications/telecommunications/our%20insights/connected%20world%20an%20evolution%20in%20connectivity%20beyond%20the%205g%20revolution/mgi_connected-world_executive-summary_february-2020.pdf">McKinsey Global Institute</a> predicted that by 2030,only a quarter of the global population will have access to high-band 5G coverage, and rollout costs are forecasted to be anywhere from $700 billion to $900 billion.
                  </p>

                  <p className="text-left">
                  Deploying new or upgraded networks isn’t the only high-cost factor—operating them heightens energy costs, and in turn, consumption. In fact, <a className="text-underline" target="_blank" href="https://www.gsmaintelligence.com/product-news/going-green-energy-efficiency-in-telecoms/">GSMA Intelligence</a> found that in 2021, energy costs stood anywhere between 15% and 40% of operators’ operating expenses. With 5G expected to bring innovation to many fields whether it’s enterprise, manufacturing, retail, etc., enabling the use cases to come will likely lead to a need for more sites and data centers. Energy costs will only grow if left unaddressed.
                  </p>

                  {/* <div className="blog_img_one">
                    <img src={targetItem.imgURL3} alt={`img-${targetItem.id}`} />
                  </div> */}

                  <h3>Why sustainability doesn’t have to be expensive or compromise quality</h3>

                  <p className="text-left">
                  The demand for modem chips isn’t going to disappear—but we can design chips with more efficient energy usage. At EdgeQ, we wanted to simultaneously address the wireless infrastructure industry’s key hurdles: affordability and adaptability. The resulting solution uses less electricity and resources to reduce TCO for operators by 50%.
                  </p>

                  <p className="text-left">
                  With that, imagine a small cell that's as adaptive as a chameleon, changing its colors and functions to match its surroundings. From architecture to technology and features, it’s about having the right outfit for every occasion. On Wednesdays, we wear “5G.” On other days, it could be 4G, both, changing from one to the other later, or even AI. This adaptability not only makes our small cells sustainable in terms of power consumption but also ensures their longevity. The highly programmable nature of our solution keeps our chips relevant and combats the costly “rip and replace” process that has traditionally been the only method to upgrade services.
                  </p>

                  <p className="text-left">
                  Performance and sustainability aren’t mutually exclusive. We’ve found that a responsible and reliable design continually evolves to meet the ever-changing demands of the digital landscape.
                  </p>
                </div>
              </div>
            </Row>
          </Container>
        </section>
      </Layout>
    )
  }
// }
export default Performanceandsustainability;
